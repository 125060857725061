import { Button, Card, FloatingLabel, Form } from 'react-bootstrap';
import React, { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';

import { useMerchantInfo } from '../../../shared-components/providers/MerchantInfoProvider';
import { UPDATE_MERCHANT_IBAN, UPDATE_WEBHOOK } from 'shared-components/queries/mutations';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';
import InfoModal from 'components/Modals/Info';
import useApiKeys from 'shared-components/hooks/useApiKeys';
import { WEBHOOKS_QUERY } from 'shared-components/queries/graphql';

import { RowItem } from './rowItem';

const Profile = () => {
  const keys = useApiKeys();
  const merchantInfo = useMerchantInfo();
  const [success, setSuccess] = useState<boolean>(false);
  const [profileSuccess, setProfileSuccess] = useState<boolean>(false);
  const [dialogShow, setDialogShow] = useState<boolean>(false);
  const [profileDialogShow, setProfileDialogShow] = useState<boolean>(false);
  const [webhooksList, setWebhooksList] = useState<any>([]);
  const webhookRef = useRef<any>(null);
  const apiKeyRef = useRef<any>(null);

  const countryList = useMemo(() => {
    countries.registerLocale(en);
    return countries.getNames('en', { select: 'official' });
  }, []);

  const webhookUpdate = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'updateWebhook',
    data: {
      query: UPDATE_WEBHOOK
    },
    auto: false,
    onResponse: (data: any) => {
      setSuccess(data.success);
      setDialogShow(true);
    },
    onErrors: (_: any) => {
      setSuccess(false);
      setDialogShow(true);
    }
  });

  const webhooks = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'webhooks',
    data: {
      query: WEBHOOKS_QUERY
    },
    auto: false,
    onResponse: (data: any) => {
      const webhooksInfo = data?.edges?.map((value: any) => {
        return value.node;
      });
      setWebhooksList(webhooksInfo);
    }
  });

  const profileUpdate = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'updateMerchant',
    data: {
      query: UPDATE_MERCHANT_IBAN
    },
    auto: false,
    onResponse: (data: any) => {
      if (data.success === true) {
        setProfileSuccess(data.success);
        setProfileDialogShow(true);
        merchantInfo.refresh();
        webhooks.execute();
      }
    }
  });

  const handleProfileUpdate = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formElements = event.currentTarget.elements;
      const iban = formElements.namedItem('iban') as HTMLInputElement;
      const bban = formElements.namedItem('bban') as HTMLInputElement;
      const accountId = formElements.namedItem('accountId') as HTMLInputElement;
      const orgNumber = formElements.namedItem('orgNumber') as HTMLInputElement;
      const country = formElements.namedItem('country') as HTMLSelectElement;
      const country3Alpha = countries.alpha2ToAlpha3(country.value);
      profileUpdate.execute({
        variables: {
          iban: iban.value,
          bban: bban.value,
          accountId: accountId.value,
          email: merchantInfo.email,
          orgNumber: orgNumber.value,
          country: country3Alpha
        }
      });
    },
    [merchantInfo.email, profileUpdate]
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formElements = event.currentTarget.elements;
      const publicKey = formElements.namedItem('publicKey') as HTMLInputElement;
      const webhookUrl = formElements.namedItem('webhook') as HTMLInputElement;
      webhookUpdate.execute({
        variables: {
          webhook: webhookUrl.value,
          publicKey: publicKey.value
        }
      });
    },
    [webhookUpdate]
  );

  const handleCurrentApiKeyWebhook = useCallback(() => {
    const currentKey = apiKeyRef.current.value;
    const currentWebhook = webhooksList.find((v: any) => v.publicKeyId === currentKey);
    webhookRef.current.value = currentWebhook === undefined ? '' : currentWebhook.webhook;
  }, [webhooksList]);

  useEffect(() => {
    webhooks.execute();
    console.log(countryList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleCurrentApiKeyWebhook();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webhooksList]);
  return (
    <>
      <InfoModal
        title={'Profile update'}
        visible={profileDialogShow}
        onOk={() => {
          setProfileDialogShow(false);
        }}
        handleClose={() => {
          setProfileDialogShow(false);
        }}
      >
        {profileSuccess ? 'Profile updated successfully' : 'Webhook creation fail'}
      </InfoModal>

      <InfoModal
        title={'Webhook info'}
        visible={dialogShow}
        onOk={() => {
          setDialogShow(false);
        }}
        handleClose={() => {
          setDialogShow(false);
        }}
      >
        {success ? 'Webhook added successfully' : 'Webhook creation fail'}
      </InfoModal>
      <Form onSubmit={handleProfileUpdate}>
        <Card className="rounded-1 pt-3 pb-2 px-2 border-0 shadow-sm">
          <RowItem label="Merchant ID" value={merchantInfo.id} />
          <RowItem label="Company Name" value={merchantInfo.name} />
          <RowItem label="Email" value={merchantInfo.email} />
          <FloatingLabel controlId="iban" label="Set IBAN number" className="mb-2">
            <Form.Control type="text" size="sm" placeholder="IBAN number" defaultValue={merchantInfo.iban} />
          </FloatingLabel>
          <FloatingLabel controlId="bban" label="Set BBAN number" className="mb-2">
            <Form.Control type="text" size="sm" placeholder="BBAN number" defaultValue={merchantInfo.bban} />
          </FloatingLabel>
          <FloatingLabel controlId="accountId" label="Set Multitude Account ID" className="mb-2">
            <Form.Control
              type="text"
              size="sm"
              placeholder="Multitude Account ID"
              defaultValue={merchantInfo.accountId}
            />
          </FloatingLabel>
          <FloatingLabel controlId="orgNumber" label="Set Organization number" className="mb-2">
            <Form.Control
              type="text"
              size="sm"
              placeholder="Organization number"
              defaultValue={merchantInfo.orgNumber}
            />
          </FloatingLabel>
          <FloatingLabel controlId="country" label="Merchant country" className="mb-2">
            <Form.Select name="country" aria-label="">
              <option>Choose merchant country</option>
              {Object.keys(countryList).map((value, key) => (
                <option key={key} value={value} selected={value === countries.alpha3ToAlpha2(merchantInfo.country)}>
                  {countryList?.[value]}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>

          <RowItem label="Counterparty ID" value={merchantInfo.counterpartyId} />
          {/*<RowItem label="Payout Fee" value={merchantInfo.payoutFee.toString()} />*/}
          <RowItem label="Deposit Fee" value={merchantInfo.depositFee.toString() || 'N/A'} />
          <RowItem label="Withdrawal Fee" value={merchantInfo.withdrawFee.toString() || 'N/A'} />
          <center>
            <Button type="submit">Update profile data</Button>
          </center>
        </Card>
      </Form>
      <Card>
        <Form onSubmit={handleSubmit}>
          <Form.Select
            aria-label="Key"
            name="publicKey"
            ref={apiKeyRef}
            onChange={() => {
              handleCurrentApiKeyWebhook();
            }}
          >
            {keys?.keys.map((element: any, key: any) => <option key={key}>{element.key}</option>)}
          </Form.Select>
          {/*
          <FloatingLabel controlId="publicKey" label="Public Key" className="mb-2">
            <Form.Control as="textarea" type="text" size="sm" placeholder="Public key" style={{ height: '200px' }} />
          </FloatingLabel>
          */}
          <FloatingLabel controlId="webhook" label="Webhook url" className="mb-2">
            <Form.Control ref={webhookRef} type="text" size="sm" placeholder="http://someurl" />
          </FloatingLabel>
          <center>
            <Button type="submit">Update webhook</Button>
          </center>
        </Form>
      </Card>
    </>
  );
};
export default Profile;
