import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { Method, Service } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';

/*

{
  "accountId" : "ID-72616",
  "companyId" : "RL-3a62c4f4-13c4-4e1e-ab2b-9831fc7160e0",
  "activationDate" : "2024-09-18",
  "accountName" : "Virtualių paslaugų operatorius UAB SEK",
  "personName" : "Virtualių paslaugų operatorius UAB",
  "iban" : "SE5190700000090701070439",
  "bic" : "FEMAMTMTXXX",
  "defaultCurrencyCode" : "SEK",
  "countryCode" : "LT",
  "statusCode" : "ACTIVE",
  "accountType" : "CURRENCY",
  "balance" : {
    "amount" : {
      "amount" : 4883321.09,
      "currency" : "SEK"
    },
    "overdraftLimitAmount" : {
      "amount" : 0.00,
      "currency" : "SEK"
    },
    "availableBalanceAmount" : {
      "amount" : 4883321.09,
      "currency" : "SEK"
    }
  },
  "accountNumber" : {
    "accountNumber" : "1070439",
    "bankCode" : "9070"
  },
  "accountSettings" : {
    "preferences" : { },
    "limitPreferences" : [ {
      "limitId" : "ID-144281",
      "timeframe" : "DAILY",
      "amount" : {
        "amount" : 320000000.00,
        "currency" : "SEK"
      }
    }, {
      "limitId" : "ID-144282",
      "timeframe" : "MONTHLY",
      "amount" : {
        "amount" : 320000000.00,
        "currency" : "SEK"
      }
    } ]
  }
}
*/

const _accountData = {
  accountId: 'ID-72616',
  companyId: 'RL-3a62c4f4-13c4-4e1e-ab2b-9831fc7160e0',
  activationDate: '2024-09-18',
  accountName: 'Virtualių paslaugų operatorius UAB SEK',
  personName: 'Virtualių paslaugų operatorius UAB',
  iban: 'SE5190700000090701070439',
  bic: 'FEMAMTMTXXX',
  defaultCurrencyCode: 'SEK',
  countryCode: 'LT',
  statusCode: 'ACTIVE',
  accountType: 'CURRENCY',
  balance: {
    amount: {
      amount: 4_883_321.09,
      currency: 'SEK'
    },
    overdraftLimitAmount: {
      amount: 0,
      currency: 'SEK'
    },
    availableBalanceAmount: {
      amount: 4_883_321.09,
      currency: 'SEK'
    }
  },
  limitPreferences: [
    {
      limitId: 'ID-144281',
      timeframe: 'DAILY',
      amount: {
        amount: 320_000_000,
        currency: 'SEK'
      }
    },
    {
      limitId: 'ID-144282',
      timeframe: 'MONTHLY',
      amount: {
        amount: 320_000_000,
        currency: 'SEK'
      }
    }
  ]
};

const MtbBalance = () => {
  const [data, setData] = useState<any>({});
  const query = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'accountDetails',
    data: {
      query: `mutation accountDetails($input: JSONString!) {
                statementProxy(input: $input) {
                    result
                }
          }`,
      variables: {
        input: JSON.stringify({
          statement: {
            accountId: 'ID-1004077',
            detailed: true,
            daysBehind: -2,
            daysAhead: 0
          }
        })
      }
    },
    auto: false,
    onResponse: (data: any) => {
      const result = data.result;
      const jsonResult = JSON.parse(result);
      setData(jsonResult);
    }
  });

  const refresh = useCallback(() => {
    query.execute({
      variables: {
        input: JSON.stringify({
          accountDetails: 'ID-72616'
        })
      }
    });
  }, [query]);
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card style={{ margin: '20px', padding: '20px' }}>
      <Card.Header>
        <Card.Title>Account ID: {data?.accountId}</Card.Title>
        <Button onClick={() => refresh()}>Refresh</Button>
      </Card.Header>
      <Card.Body>
        <Card.Text>Company ID: {data?.companyId}</Card.Text>
        <Card.Text>Activation Date: {data?.activationDate}</Card.Text>
        <Card.Text>Account Name: {data?.accountName}</Card.Text>
        <Card.Text>Person Name: {data?.personName}</Card.Text>
        <Card.Text>IBAN: {data?.iban}</Card.Text>
        <Card.Text>BIC: {data?.bic}</Card.Text>
        <Card.Text>Default Currency Code: {data?.defaultCurrencyCode}</Card.Text>
        <Card.Text>Country Code: {data?.countryCode}</Card.Text>
        <Card.Text>Status Code: {data?.statusCode}</Card.Text>
        <Card.Text>Account Type: {data?.accountType}</Card.Text>
        <Card.Text>
          Balance Amount: {data?.balance?.amount?.amount} {data?.balance?.amount?.currency}
        </Card.Text>
        <Card.Text>
          Overdraft Limit Amount: {data?.balance?.overdraftLimitAmount?.amount}{' '}
          {data?.balance?.overdraftLimitAmount?.currency}
        </Card.Text>
        <Card.Text>
          Available Balance Amount: {data?.balance?.availableBalanceAmount?.amount}{' '}
          {data?.balance?.availableBalanceAmount?.currency}
        </Card.Text>
        <Card.Subtitle className="mb-2 text-muted">Limit Preferences:</Card.Subtitle>
        {data?.limitPreferences?.map((limit: any) => (
          <div key={limit.limitId} style={{ marginLeft: '20px' }}>
            <Card.Text>Limit ID: {limit?.limitId}</Card.Text>
            <Card.Text>Timeframe: {limit?.timeframe}</Card.Text>
            <Card.Text>
              Amount: {limit?.amount?.amount} {limit?.amount?.currency}
            </Card.Text>
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

export default MtbBalance;
