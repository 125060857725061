import { useState, useCallback } from 'react';

import { MTB_QUERY } from 'shared-components/queries/graphql';
import { Method, Service } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { useSWCallbackSignEvent, useSWCallbackStateEvent } from 'shared-components/providers/SWEventProvider';
import { PendingPaymentType } from 'shared-components/types/PendingPaymentType';

type MtbTransactionsHookProps = {
  first?: number;
  last?: number;
  after?: string;
  before?: string;
};
const useMtbTransactions = () => {
  const [rfqList, setRfqList] = useState<any>([]);
  const rfqs = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'mtbCashEntries',
    data: {
      query: MTB_QUERY
    },
    auto: false,
    onResponse: (data: any) => {
      const rfq = {
        data: {},
        pageInfo: {},
        totalCount: 0
      };
      rfq.data = data?.edges?.map((value: any) => {
        const current = value.node;
        return current;
      });
      rfq.pageInfo = data?.pageInfo;
      rfq.totalCount = data.totalCount;
      //        .sort((a: any, b: any) => (a.createdAt > b.createdAt ? -1 : 1));
      setRfqList(rfq);
    }
  });

  useSWCallbackStateEvent((data: any) => {
    const operationProgress = data.operationProgress;
    if (operationProgress.state !== 'running') {
      return;
    }
    if (
      operationProgress.currentTask.includes('resolve merchant by id') ||
      operationProgress.currentTask.includes('select user account')
    )
      fetchList({});
  });

  useSWCallbackSignEvent((data: any) => {
    const signState = data.piSignState;
    const operationId = data.operationId;
    const rfq = rfqList?.find((value: PendingPaymentType) => {
      return value.operationId === operationId;
    });
    if (rfq.status?.toLowerCase() !== signState.status.toLowerCase()) {
      rfqs.execute();
    }
  });

  const fetchList = useCallback(
    ({ first, last, after, before }: MtbTransactionsHookProps) => {
      rfqs.execute({
        variables: {
          first,
          last,
          after,
          before
        }
      });
    },
    [rfqs]
  );

  return { data: rfqList, fetchList };
};

export default useMtbTransactions;
