import { Col, Container, Row } from 'react-bootstrap';
import React /*{ useEffect, useMemo, useState }*/ from 'react';
/*
import AccountSummary from '../../../components/AccountSummary';
import { MerchantTransaction } from '../../../types/grapql';
import { TRANSACTIONS_QUERY } from 'shared-components/queries/graphql';
import { useBalances } from '../../../contexts/balances';
import useRfqs from '../../../hooks/useRfqs';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';
*/
const DashboardUser = () => {
  //  const balances = useBalances();

  //  const { data: rfqList } = useRfqs();
  /*
  const eurBalance = useMemo(() => {
    const broker = balances?.balances?.filter((account: any) => {
      return account.accountType === 'Broker';
    });
    if (!broker) {
      return (0).toFixed(2);
    }
    const amount = broker[0]?.balance?.filter((balance: any) => {
      return balance?.currencyName === 'EURX';
    })?.[0]?.amount;
    return Number.parseFloat(amount).toFixed(2);
  }, [balances?.balances]);

  const eurPendingBalance = useMemo(() => {
    if (!rfqList) {
      return 0;
    }
    return rfqList.data
      ?.filter((data: { eurRequest: number; status: string }) => data.status === 'CAPTURED')
      .reduce((acc: number, data: { eurRequest: number; status: string }) => acc + data.eurRequest, 0)
      .toFixed(2);
  }, [rfqList]);

  //const [data, setData] = useState([]);
  /*
  const _query = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'merchantTx',
    data: {
      query: TRANSACTIONS_QUERY
    },
    auto: false,
    onResponse: (data: any) => {
      setData(data);
    }
  });
*/
  /*
  useEffect(() => {
    //query.execute();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
*/
  /*  const deposit = useMemo(() => {
    return rfqList.data
      ?.filter((data: { eurRequest: number; status: string }) => data.status === 'PAID')
      .reduce((acc: number, data: { eurRequest: number; status: string }) => acc + data.eurRequest, 0)
      .toFixed(2);
  }, [rfqList]);
*/
  /*
  const withdrawal = useMemo(() => {
    let amount = 0;

    for (const element of data as MerchantTransaction[]) {
      if (element.source?.toUpperCase() === 'WITHDRAWAL') {
        amount += Number.parseFloat(element.amount ?? '0');
      }
    }
    return amount.toFixed(2);
  }, [data]);
*/
  return (
    <>
      <Container fluid>
        test2
        <Row className="mb-2">
          <Col>
            <Container fluid>
              {/* <PendingTransactions /> */}
              {/*  <Stats /> */}
              {/*<Withdrawbox /> */}
            </Container>
          </Col>
        </Row>
        {/* 
        <AccountSummary
          withdrawals={`${withdrawal} EUR`}
          balance={`${eurBalance} EUR`}
          pendingBalance={`${eurPendingBalance} EUR`}
          deposits={`${deposit} EUR`}
        />
        */}
      </Container>
    </>
  );
};

export default DashboardUser;
