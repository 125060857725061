import React, { useEffect, useState } from 'react';
import DataTable, { ExpanderComponentProps, TableColumn } from 'react-data-table-component';
import { Col, Row } from 'react-bootstrap';

import clsx from 'clsx';

import { PendingPaymentType } from '../../../types/grapql';
import { useMerchantInfo } from 'shared-components/providers/MerchantInfoProvider';
import { useApp } from 'shared-components/providers/AppProvider';
import useMtbTransactions from 'hooks/useMtbTransactions';

import styles from './style.module.scss';

export const ExpandedComponent: React.FC<ExpanderComponentProps<PendingPaymentType>> = ({ data }) => {
  return (
    <>
      {data.kycData ? (
        <div className={styles.kycContent}>
          <Row>
            <Col xs={12} md={6}>
              <Row className={styles.kycField}>
                <Col xs={4} className={styles.label}>
                  Transaction ID
                </Col>
                <Col xs={8} className={styles.value}>
                  {data.operationId}
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row className={styles.kycField}>
                <Col xs={4} className={styles.label}>
                  Personal ID
                </Col>
                <Col xs={8} className={styles.value}>
                  {data.clientPid}
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row className={styles.kycField}>
                <Col xs={4} className={styles.label}>
                  Receiving IBAN/BBAN
                </Col>
                <Col xs={8} className={styles.value}>
                  {data.iban !== null && data.iban!.length > 0 ? data.iban : data.bban}
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row className={styles.kycField}>
                <Col xs={4} className={styles.label}>
                  Sending IBAN/BBAN
                </Col>
                <Col xs={8} className={styles.value}>
                  {data.clientIban !== null && data.clientIban!.length > 0 ? data.clientIban : data.clientBban}
                </Col>
              </Row>
            </Col>

            {Object.keys(data.kycData!)?.map((val, key) => {
              const value = (data.kycData as any)[val] ?? '';
              if (typeof value === 'object') {
                return (
                  <>
                    {Object.keys(value)?.map((val, key) => {
                      const intval = (value as any)[val] ?? '';
                      return (
                        <Col key={key} xs={12} md={6}>
                          <Row className={styles.kycField}>
                            <Col xs={4} className={styles.label}>
                              {val}
                            </Col>
                            <Col xs={8} className={styles.value}>
                              {intval}
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                  </>
                );
              }
              return (
                <Col key={key} xs={12} md={6}>
                  <Row className={styles.kycField}>
                    <Col xs={4} className={styles.label}>
                      {val}
                    </Col>
                    <Col xs={8} className={styles.value}>
                      {value}
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : (
        <div />
      )}
      {data.depositData ? (
        <div className={styles.kycContent}>
          <Row>
            {Object.keys(data.depositData!)?.map((val, key) => {
              const value = (data.depositData as any)[val] ?? '';
              return (
                <Col key={key} xs={12} md={6}>
                  <Row className={styles.kycField}>
                    <Col xs={4} className={styles.label}>
                      {val}
                    </Col>
                    <Col xs={8} className={styles.value}>
                      {value}
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

const MtbTransactions = () => {
  const {
    values: { searchBox },
    setters: { setSearchBox }
  } = useApp();

  const { data, fetchList } = useMtbTransactions();
  const { name } = useMerchantInfo();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    setSearchBox('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handlePageChange(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handlePageChange(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, searchBox]);

  useEffect(() => {
    console.log(data);
    setTotalRows(data.totalCount);
    setLoading(false);
  }, [data]);

  const handlePageChange = (page: number) => {
    setLoading(true);
    const totalPages = Math.ceil(data.totalCount / perPage);
    if (page === 1) {
      fetchList({ first: perPage });
    } else if (page >= totalPages) {
      fetchList({ last: perPage });
    } else if (page > currentPage) {
      fetchList({ first: perPage, after: data.pageInfo.endCursor });
    } else if (page < currentPage) {
      fetchList({ last: perPage, before: data.pageInfo.startCursor });
    } else {
      fetchList({ first: perPage, after: data.pageInfo.endCursor });
    }
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, _: any) => {
    setPerPage(newPerPage);
  };

  const columns: TableColumn<any>[] = [
    {
      name: 'transaction ID',
      cell: (row) => {
        if (row.transactionId) {
          return row.transactionId;
        }
        return '';
      },
      wrap: false,
      width: '80px'
    },
    {
      name: 'Transaction id',
      selector: (row) => row.transactionId,
      wrap: false
    },
    {
      name: 'Group id',
      selector: (row) => row.groupId,
      wrap: false
    },
    {
      name: 'Posting date',
      selector: (row) => row.postingDate,
      wrap: false
    },
    {
      name: 'Account id',
      selector: (row) => row.accountId,
      wrap: false
    },
    {
      name: 'Transaction type code',
      selector: (row) => row.transactionTypeCode,
      wrap: false
    },
    {
      name: 'Transaction subtype code',
      selector: (row) => row.transactionSubtypeCode,
      wrap: false
    },
    {
      name: 'Direction code',
      selector: (row) => row.directionCode,
      wrap: false
    },
    {
      name: 'Initial balance amount',
      selector: (row) => row.initialBalanceAmount,
      wrap: false
    },
    {
      name: 'Transaction amount',
      selector: (row) => row.transactionAmount,
      wrap: false
    },
    {
      name: 'Currency code',
      selector: (row) => row.currencyCode,
      wrap: false
    },
    {
      name: 'Counterparty name',
      selector: (row) => row.counterpartyName,
      wrap: false
    },
    {
      name: 'Counterparty account number',
      selector: (row) => row.counterpartyAccountNumber,
      wrap: false
    },
    {
      name: 'Counterparty account number type code',
      selector: (row) => row.counterpartyAccountNumberTypeCode,
      wrap: false
    },
    {
      name: 'Counterparty account number subtype',
      selector: (row) => row.counterpartyAccountNumberSubtype,
      wrap: false
    },
    {
      name: 'Counterparty account number country code',
      selector: (row) => row.counterpartyAccountNumberCountryCode,
      wrap: false
    },
    {
      name: 'Counterparty financial institution id',
      selector: (row) => row.counterpartyFinancialInstitutionId,
      wrap: false
    },
    {
      name: 'Counterparty financial institution id type code',
      selector: (row) => row.counterpartyFinancialInstitutionIdTypeCode,
      wrap: false
    },
    {
      name: 'Details',
      selector: (row) => row.details,
      wrap: false
    },
    {
      name: 'Meta info',
      selector: (row) => row.metaInfo,
      wrap: false
    },
    {
      name: 'Merchant info',
      selector: (row) => row.merchantInfo,
      wrap: false
    },
    {
      name: 'End to end id',
      selector: (row) => row.endToEndId,
      wrap: false
    },
    {
      name: 'Transaction d time',
      selector: (row) => row.transactionDTime,
      wrap: false
    },
    {
      name: 'Payment service provider code',
      selector: (row) => row.paymentServiceProviderCode,
      wrap: false
    },
    {
      name: 'Labels',
      selector: (row) => row.labels,
      wrap: false
    },
    {
      name: 'Reference number',
      selector: (row) => row.referenceNumber,
      wrap: false
    },
    {
      name: 'Contract source name',
      selector: (row) => row.contractSourceName,
      wrap: false
    },
    {
      name: 'Contract source ref',
      selector: (row) => row.contractSourceRef,
      wrap: false
    },
    {
      name: 'Contract ref',
      selector: (row) => row.contractRef,
      wrap: false
    },
    {
      name: 'Value date',
      selector: (row) => row.valueDate,
      wrap: false
    },
    {
      name: 'Is reversed',
      selector: (row) => row.isReversed,
      wrap: false
    },
    {
      name: 'Reversed transaction id',
      selector: (row) => row.reversedTransactionId,
      wrap: false
    },
    {
      name: 'Filing code',
      selector: (row) => row.filingCode,
      wrap: false
    },
    {
      name: 'Report date type',
      selector: (row) => row.reportDateType,
      wrap: false
    },
    {
      name: 'Domain code',
      selector: (row) => row.domainCode,
      wrap: false
    },
    {
      name: 'Family code',
      selector: (row) => row.familyCode,
      wrap: false
    },
    {
      name: 'Subfamily code',
      selector: (row) => row.subfamilyCode,
      wrap: false
    },
    {
      name: 'Source name',
      selector: (row) => row.sourceName,
      wrap: false
    },
    {
      name: 'Source ref',
      selector: (row) => row.sourceRef,
      wrap: false
    },
    {
      name: 'Transaction group code',
      selector: (row) => row.transactionGroupCode,
      wrap: false
    },
    {
      name: 'Virtual account id',
      selector: (row) => row.virtualAccountId,
      wrap: false
    },
    {
      name: 'Unique identifier',
      selector: (row) => row.uniqueIdentifier,
      wrap: false
    }
  ];

  useEffect(() => {
    handlePageChange(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);
  return (
    <>
      <DataTable
        highlightOnHover={true}
        columns={columns}
        data={data.data ?? []}
        progressPending={loading}
        pagination
        paginationServer
        paginationPerPage={30}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={{
          headCells: {
            style: {
              backgroundColor: 'lightgray',
              textTransform: 'uppercase'
            }
          }
        }}
        className={clsx(styles.table, 'shadow p-3 mb-5 bg-white overflow-auto')}
      />
    </>
  );
};
export default MtbTransactions;
